//  icon use as img here
import icon from "../../assets/images/icons/icon-1.svg";
import icon1 from "../../assets/images/icons/icon-2.svg";
import icon2 from "../../assets/images/icons/icon-3.svg";
import icon3 from "../../assets/images/icons/icon-4.svg";
import icon4 from "../../assets/images/icons/icon-5.svg";
import icon5 from "../../assets/images/icons/icon-6.svg";

const experience = [
  {
    id: "pratiche-comunali",
    icon: icon2,
    title: "Urbanistica",
    des: "Pratiche comunali, attività edilizia libera (C.I.L./C.I.L.A e S.C.I.A.), permessi di costruire.",
    color: "#8774FF",
    bg: "#FCF4FF",
  },
  {
    id: "valutatore-immobiliare-certificato",
    icon: icon,
    title: "Valutazioni immobiliari",
    des: "Valutazioni immobiliari redatte secondo gli Standard Europei di Valutazione EVS (European Valuation Standards).",
    color: "#D566FF",
    bg: "#FCF4FF",
  },
  {
    id: "stime-valutazioni",
    icon: icon3,
    title: "Stime, rapporti di valutazioni e perizie giurate",
    des: "Perizie tecnico legali, accertamento stato dei luoghi, verifiche e valutazione danni immobiliari.",
    color: "#D566FF",
    bg: "#FCF4FF",
  },
  {
    id: "ctu-ctp",
    icon: icon2,
    title: "Consulente tecnico d'ufficio e di parte",
    des: "Consulente tecnico ufficio (CTU) e consulente tecnico di parte (CTP).",
    color: "#8774FF",
    bg: "#FCF4FF",
  },
  {
    id: "pratiche-certificazioni-catastali",
    icon: icon,
    title: "Pratiche catastali",
    des: "Aggiornamenti catastali, istanze/volture, catasto terreni e urbano, variazioni planimetriche DO.C.FA.",
    color: "#D566FF",
    bg: "#FCF4FF",
  },
  {
    id: "agibilita",
    icon: icon3,
    title: "Condoni edilizi/agibilità",
    des: "Redazione, ricerca, integrazione e ritiro di pratiche di condono edilizio.",
    color: "#FF6080",
    bg: "#FFF4F4",
  },
  {
    id: "capitolati",
    icon: icon4,
    title: "Capitolati, computi metrici, progettazione e D.L. (Direzione Lavori)",
    des: "",
    color: "#FF75D8",
    bg: "#FFF0F8",
  }, {
    id: "consulenze-tecnico-fiscali",
    icon: icon5,
    title: "Consulenze tecnico-fiscali in campo immobiliare",
    des: "",
    color: "#269FFF",
    bg: "#F3FAFF",
  }, {
    id: "ape",
    icon: icon5,
    title: "Attestato di prestazione energetica (APE)",
    des: "Redazione di Certificati di Prestazione Energetica APE per Compravendita, Locazione ed altri passaggi di proprietà",
    color: "#269FFF",
    bg: "#F3FAFF",
  }, {
    id: "rendering",
    icon: icon5,
    title: "Rendering architettonici 3D",
    des: "Progettazione 3D e modellazione di spazi d’interni, esterni e concept.",
    color: "#269FFF",
    bg: "#F3FAFF",
  }]

export default experience;